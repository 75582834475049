import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CTA from "../components/cta"

import { motion } from "framer-motion"

const CornerstoneEducation = () => (
  <Layout>
    <SEO title="Cornerstone Education - UK Based Freelance Web Designer/Developer" />
    <div className="container mx-auto px-4 max-w-7xl relative z-20">
      <div className="rounded-2xl overflow-hidden relative">
        <StaticImage className="absolute left-0 top-0 w-full h-full rounded-2xl overflow-hidden round-img z-10 work-hero" src="../images/cornerstone-education-thumb.jpg" alt="Cornerstone Education" />
        <div className="w-full bg-zinc-900 bg-opacity-60 md:bg-opacity-20 bg-gradient-to-t from-zinc-900 to-transparent pt-24 md:pt-36 rounded-2xl relative z-20">
            <div className="p-8 md:p-12 lg:p-16 text-white  w-full">
                      <motion.h1 initial={{ opacity: 0, y: 15 }} whileInView={{ opacity: 1, y: 0 }} viewport={{ once: true }} className="text-5xl md:text-6xl flex flex-wrap lg:text-7xl font-medium leading-tight md:leading-tight lg:leading-tight tracking-tighter">Cornerstone Education</motion.h1>

              <div className="grid grid-cols-1 md:grid-cols-1 gap-8 md:gap-10 lg:gap-20 xl:gap-24 pt-6 md:pt-10">
          <motion.div initial={{ opacity: 0, y: 15 }} whileInView={{ opacity: 1, y: 0, transition: { delay: 0.075 } }} viewport={{ once: true }}>
            <div className="uppercase tracking-wider text-sm md:text-base font-medium mb-3 md:mb-4">
                About the Client
            </div>
            <div className="text-base md:text-lg lg:text-xl lg:leading-normal text-white md:opacity-90 font-light">
              <p>
                Cornerstone Education helps international students enroll in universities in the United States, helping with everything from initial university research to preparation for the trip to the U.S.
              </p>
            </div>
          </motion.div>
        </div>
            </div>
          </div>
      </div>
        
        
    </div>

    <motion.div initial={{ opacity: 0, y: 15 }} whileInView={{ opacity: 1, y: 0, transition: { delay: 0.125 } }} viewport={{ once: true }} className="relative z-20 pt-16 md:pt-24 lg:pt-36">
      <div className="container mx-auto px-4 max-w-7xl">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-5 md:gap-10 lg:gap-20 xl:gap-24">
        <div className="md:col-span-8">
          <div className="text-sm md:text-base lg:text-lg text-zinc-500 space-y-3 md:space-y-4">
            <p>
              The client was struggling to find a design that worked for them, so they brought me on-board to refresh the design of the entire web app. I worked directly with the client's development team and used the Material Design system to help the developers implement my design quicker.
            </p>
            <p>I handed off the project in Figma, with detailed designs of mobile, tablet and desktop versions.</p>
          </div>
          
        </div>
        
        <div className="md:col-span-4">
        <div className="uppercase tracking-wider text-sm md:text-base font-medium mb-2 md:mb-2.5 md:mt-2">
                Services I Provided
            </div>
            <div className="text-sm md:text-base text-zinc-700">
              <p>
               Web Design, Logo Design
              </p>
            </div>
          </div>
          
        </div>
        
      </div>
      <div className="mx-auto px-4 max-w-[1536px] pt-10 md:pt-16">
        <div className="grid grid-cols-12 gap-4 md:gap-6 lg:gap-8">
          <StaticImage className="h-auto col-span-12 md:col-span-6 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/cornerstone-education-1.png" alt="Cornerstone Education" />
          <StaticImage className="h-auto col-span-12 md:col-span-6 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/cornerstone-education-2.png" alt="Cornerstone Education" />
          <StaticImage className="h-auto col-span-12 md:col-span-6 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/cornerstone-education-3.png" alt="Cornerstone Education" />
          <StaticImage className="h-auto col-span-12 md:col-span-6 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/cornerstone-education-4.png" alt="Cornerstone Education" />
          <StaticImage className="h-auto col-span-6 md:col-span-3 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/cornerstone-education-5.png" alt="Cornerstone Education" />
          <StaticImage className="h-auto col-span-6 md:col-span-3 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/cornerstone-education-6.png" alt="Cornerstone Education" />
          <StaticImage className="h-auto col-span-6 md:col-span-3 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/cornerstone-education-7.png" alt="Cornerstone Education" />
          <StaticImage className="h-auto col-span-6 md:col-span-3 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/cornerstone-education-8.png" alt="Cornerstone Education" />

        </div>
      </div>
      
    </motion.div>
    <CTA></CTA>
  </Layout>
)

export default CornerstoneEducation
